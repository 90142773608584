import React from "react";

import "./Input.scss";
import classNames from "classnames";
import {Icon} from "../icon/Icon";

export const Input = ({ value, onChange, onFocus, id, type, placeholder, error, variant, icon }) => {
  const inputClasses = classNames("input", {
    error: error,
    [variant]: variant,
    hasIcon: icon
  });
  return (
      <div className="input-wrapper">
        {icon && <Icon icon={icon}/>}
        <input
            className={inputClasses}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            id={id}
            type={type}
            placeholder={placeholder}
        />
      </div>
  );
};
