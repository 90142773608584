import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {Page} from "../../ui/page/Page";
import {ReactComponent as usersIcon} from "../../../assets/icons/users_icon.svg";
import {Form} from "../../ui/form/Form";
import {Label} from "../../ui/label/Label";
import {Input} from "../../ui/input/Input";
import {PhotoInput} from "../../ui/photoInput/PhotoInput";
import {Checkbox} from "../../ui/checkbox/Checkbox";

import {useFormik} from "formik";
import * as yup from "yup";
import {Error} from "../../ui/error/Error";

import * as actions from "./UserActions"
import _ from "lodash";
import {SelectInput} from "../../ui/select/SelectInput";

const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    changePassword: yup.boolean(),
    password: yup.string().when("changePassword", {
        is: true, then: () => yup.string().required("Password is required"),
    }),
    passwordConfirm: yup.string().when("changePassword", {
        is: true, then: () => yup
            .string()
            .required("Confirm Password is required")
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .min(6, "Password must have min. 6 characters"),
    }),
});

const roles = [{value: "admin", label: "Admin"}, {value: "user", label: "User"}]

export const User = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [prevName, setPrevName] = useState("");
    const {
        values, handleChange, handleSubmit, errors, touched, setFieldValue, setValues,
    } = useFormik({
        initialValues: {
            name: "",
            surname: "",
            email: "",
            changePassword: true,
            password: "",
            passwordConfirm: "",
            image: null,
            role: "",
        }, validationSchema: validationSchema, onSubmit: (values) => {
            actions.saveUser({
                ..._.omit(values, "passwordConfirm", "image", "changePassword"),
                photo_id: values.image?.id || null,
            }, () => navigate("/users"))
        },
    });

    useEffect(() => {
        if (params.id) {
            actions.getUser(params.id, (user) => {
                setPrevName(user.name);
                setValues({...user, "changePassword": false});
            });
        }
    }, []);

    return (<Page
        icon={usersIcon}
        title="Users"
        subTitle={params.id ? `${prevName}` : "New"}
        buttonName="Save"
        onButtonClick={handleSubmit}>
        <form autoComplete="off">
            <Form>
                <Label required>Name</Label>
                <Input
                    id="name"
                    error={errors.name && touched.name}
                    value={values.name}
                    onChange={handleChange}
                />
                <Error>{errors.name && touched.name && errors.name}</Error>
                <Label>Surname</Label>
                <Input id="surname" value={values.surname} onChange={handleChange}/>
                <Label required>Email</Label>
                <Input
                    type="email"
                    id="email"
                    error={errors.email && touched.email}
                    value={values.email}
                    onChange={handleChange}
                />
                <Error>{errors.email && touched.email && errors.email}</Error>
                {!params.id && (<>
                    <Label required>Password</Label>
                    <Input
                        id="password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        error={errors.password && touched.password}
                    />
                    <Error>{errors.password && touched.password && errors.password}</Error>
                    <Label required>
                        Confirm
                        <br/>
                        Password
                    </Label>
                    <Input
                        id="passwordConfirm"
                        type="password"
                        value={values.passwordConfirm}
                        error={errors.passwordConfirm && touched.passwordConfirm}
                        onChange={handleChange}
                    />
                    <Error>
                        {errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm}
                    </Error></>)}
                <Label required>Role</Label>
                <SelectInput options={roles}
                        value={roles.find(role => role.value === values.role)}
                        onChange={(option) => setFieldValue("role", option.value)}/>
                <Label>Profile Picture</Label>
                <PhotoInput
                    value={values.image?.url}
                    onPhotoUpload={setFieldValue}
                />
                {params.id && (<>
                    <Label>Change Password?</Label>
                    <div
                        style={{
                            display: "flex", alignItems: "center",
                        }}>
                        <Checkbox id="changePassword" onChange={setFieldValue}/>
                    </div>
                    {values.changePassword && (<>
                        <Label required>Password</Label>
                        <Input
                            id="password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            error={errors.password && touched.password}
                        />
                        <Error>{errors.password && touched.password && errors.password}</Error>
                        <Label required>
                            Confirm
                            <br/>
                            Password
                        </Label>
                        <Input
                            id="passwordConfirm"
                            type="password"
                            value={values.passwordConfirm}
                            error={errors.passwordConfirm && touched.passwordConfirm}
                            onChange={handleChange}
                        />
                        <Error>
                            {errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm}
                        </Error></>)}
                </>)}
            </Form>
        </form>
    </Page>);
};
