import axios from "axios";

export const getCategory = (id, callback) => {
    const config = {
        url: `categories/${id}`,
        method: "GET",
    };
    axios.request(config).then((response) => {
        callback(response.data);
    });
};

export const saveCategory = (category, callback) => {
    const id = category.id
    const config = {
        url: id ? `categories/${id}` : "categories",
        method: id ? "PATCH" : "POST",
        data: category,
    };
    axios.request(config).then(() => callback());
};
