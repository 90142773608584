import React from "react";
import Dropzone from "react-dropzone";

import "./PhotoInput.scss";

import { ReactComponent as uploadIcon } from "../../../assets/icons/upload_icon.svg";
import { Icon } from "../icon/Icon";
import * as actions from "./PhotoInputActions";

export const PhotoInput = ({ value, onPhotoUpload }) => {
    const onUpload = (image) => {
        const formData = new FormData
        formData.append("photo[image]", image)
        actions.savePhoto(formData, (photo) => onPhotoUpload("image", photo))
    };

    const onDelete = () => {
        onPhotoUpload("image", null);
    };

    return (
    <Dropzone
      noClick={!!value}
      onDrop={(acceptedFile) => {
        onUpload(acceptedFile[0]);
      }}>
      {({ getRootProps, getInputProps }) => (
        <div>
          <div {...getRootProps()} className="photo-input">
            <input {...getInputProps()} />

            {value ? (
              <div className="preview" onClick={onDelete}>
                <img src={value} alt="preview" />
                <div className="overlay">Remove</div>
              </div>
            ) : (
              <>
                <Icon icon={uploadIcon} />
                <p className="label">
                  <span className="action">Click to Upload</span> or drag and
                  drop
                </p>
                <p className="label">(Max File size: 30MB)</p>
              </>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );
};
