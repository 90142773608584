import React from 'react';
import "./TextEditor.scss"

import {Editor} from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import classNames from "classnames";

export const TextEditor = ({value, onChange}) => {
    return (
        <div className={"text-editor"}>
            <Editor editorClassName="editor"
                    toolbarClassName="toolbar"
                    editorState={value}
                    onEditorStateChange={onChange}
                    toolbar={{
                        options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "image", "link", "remove", "emoji", "history"],
                        inline: {
                            className: "option",
                            options: ['bold', 'italic', 'underline', 'strikethrough', "subscript", "superscript"]
                        }
                    }}/>
        </div>
    );
};