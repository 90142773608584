import React from "react";
import Select from "react-select";
import {Option} from "./Option";


export const SelectInput = ({options, value, onChange, isMulti, isClearable = false, error, placeholder}) => {
    return <Select components={isMulti && {Option}} styles={{
        control: (baseStyles, state) => ({
            ...baseStyles,
            padding: "2px 8px",
            borderRadius: "8px",
            backgroundColor: error ? "rgba(255,0,0, 0.1)" : "white",
            border: error ? "2px solid rgba(255,0,0,0.4)" : null,
            minWidth: "250px",
        }),
        option: (baseStyles, {isFocused, isSelected}) => ({
            ...baseStyles,
            color: isFocused ? "#877fe9" : undefined,
            backgroundColor: isSelected ? !isMulti ? "rgba(0,0,0,0.05)" : undefined : undefined
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: error ? "rgba(255,0,0,0.6)" : "rgba(0,0,0,0.2)",
            fontWeight: error && 600,
            fontSize: "14px"
        }),
        multiValue: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: "#E9E1FF",
            color: "rgba(0,0,0,0.6)",
            padding: "2px 4px",
            borderRadius: "8px"
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: "rgba(0,0,0,0.6)"
        }),
        multiValueRemove: (baseStyles) => ({
            ...baseStyles,
            ":hover": {
                backgroundColor: "rgba(0,0,0,0.1)"
            }
        }),
    }} options={options} value={value} placeholder={placeholder} onChange={onChange} isMulti={isMulti}
                   isClearable={isClearable} hideSelectedOptions={false}/>;
};
