import axios from "axios";

export const login = (user, catchError, callback) => {
    const config = {
        url: "users/sign_in",
        method: "POST",
        data: {user}
    }
    axios.request(config).then((response) => callback({
        token: response.headers.authorization,
        user: response.data
    })).catch(() => {
        catchError()
    })
}