import React, { useEffect, useState } from "react";
import { Page } from "../../ui/page/Page";

import { ReactComponent as tagsIcon } from "../../../assets/icons/tags_icon.svg";
import { useNavigate } from "react-router-dom";

import * as actions from "./TagsActions";
import { Table } from "../../features/table/Table";
import { DeleteModal } from "../../features/delete-modal/DeleteModal";

export const Tags = () => {
  const navigate = useNavigate();

  const [tags, setTags] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const columns = [
    {
      Header: "Tag",
      accessor: "name",
    },
  ];

  const fetchTags = () => {
    actions.fetchTags((response) => {
      setTags(response.tags)
      setTotalPages(response.pages)
    }, page);
  }

  useEffect(() => {
    fetchTags()
  }, [page]);

  const deleteTag = (id) => {
    actions.deleteTag(id, () => {
      setTags((prev) => prev.filter((tag) => tag.id !== id));
      setShowDeleteModal(false);
    });
  };

  const handleEditClick = (id) => {
    navigate(`/tag/${id}`);
  };

  const handleDeleteClick = (name, id) => {
    setShowDeleteModal({ name: name, id: id });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <Page
      icon={tagsIcon}
      title="Tags"
      buttonName="+ Add new"
      onButtonClick={() => navigate("/tag")}>
      <Table
        columns={columns}
        data={tags}
        onDelete={handleDeleteClick}
        onEdit={handleEditClick}
        totalPages={totalPages}
        currentPage={page}
        onPageChange={setPage}
        isPaginated
      />
      {showDeleteModal && (
        <DeleteModal
          title="Tags"
          element="tag"
          toDeleteName={showDeleteModal.name}
          onClose={closeDeleteModal}
          onDelete={() => deleteTag(showDeleteModal.id)}
        />
      )}
    </Page>
  );
};
