import {Navbar} from "./components/features/navbar/Navbar";

import {Route, Routes, useLocation} from "react-router-dom";
import {Categories} from "./components/pages/categories/Categories";
import {Category} from "./components/pages/categories/Category";
import {Ingredients} from "./components/pages/ingredients/Ingredients";
import {Ingredient} from "./components/pages/ingredients/Ingredient";
import {Tags} from "./components/pages/tags/Tags";
import {Tag} from "./components/pages/tags/Tag";
import {Recipes} from "./components/pages/recipes/Recipes";
import {Recipe} from "./components/pages/recipes/Recipe";

import {Users} from "./components/pages/users/Users";
import {User} from "./components/pages/users/User";
import {Login} from "./components/pages/login/Login";
import {ProtectedRoute} from "./components/features/protectedRoute/ProtectedRoute";

import {Articles} from "./components/pages/articles/Articles";
import {SubPages} from "./components/pages/subPages/SubPages";
import {SubPage} from "./components/pages/subPages/SubPage";
import {Article} from "./components/pages/articles/Article";

import {Reports} from "./components/pages/reports/Reports";
import {Search} from "./components/pages/search/Search";
import {Public} from "./components/pages/public/Public";
import {PublicRecipes} from "./components/pages/public/PublicRecipes/PublicRecipes";
import {PublicArticles} from "./components/pages/public/PublicArticles/PublicArticles";
import {PublicSubpage} from "./components/pages/public/PublicSubpage/PublicSubpage";
import {PublicRecipe} from "./components/pages/public/PublicRecipes/PublicRecipe";
import {PublicArticle} from "./components/pages/public/PublicArticles/PublicArticle";


function App() {
  const {pathname} = useLocation()
  const isAuth = () => JSON.parse(localStorage.getItem("auth"))
  const currentUserRole = () => isAuth()?.user.role

  return (
    <div className="App">
      {pathname !== "/login" && isAuth() && <Navbar />}
      <Routes>
        <Route path="/login" element={<Login /> } />

        {!isAuth() && (
            <Route path="/public/:id" element={<Public />} >
              <Route path="recipes" element={<PublicRecipes />} />
              <Route path="recipes/:recipe_id" element={<PublicRecipe />} />
              <Route path="articles" element={<PublicArticles />} />
              <Route path="articles/:article_id" element={<PublicArticle />} />
              <Route path=":subpage" element={<PublicSubpage />} />
            </Route>
        )}

        <Route element={<ProtectedRoute isAllowed={isAuth()} /> } >
          <Route path="/" element={<></>} />
          <Route path="/search/:in?/:query?" element={<Search />} />
        </Route>

        <Route element={<ProtectedRoute isAllowed={currentUserRole() === "user"} />} >
          <Route path="/categories" element={ <Categories />} />
          <Route path="/category/:id?" element={ <Category />} />
          <Route path="/ingredients" element={ <Ingredients />} />
          <Route path="/ingredient/:id?" element={ <Ingredient />} />
          <Route path="/tags" element={ <Tags /> } />
          <Route path="/tag/:id?" element={ <Tag />} />
          <Route path="/recipes?" element={ <Recipes /> } />
          <Route path="/recipe/:id?" element={ <Recipe /> } />
          <Route path="/articles" element={<Articles/>}/>
          <Route path="/article/:id?" element={<Article/>}/>
          <Route path="/sub-pages" element={<SubPages/>}/>
          <Route path="/sub-page/:id?" element={<SubPage/>}/>
          <Route path="/reports" element={ <Reports /> } />
        </Route>

        <Route element={<ProtectedRoute isAllowed={currentUserRole() === "admin"} />}>
          <Route path="/users" element={<Users /> } />
          <Route path="/user/:id?" element={<User />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
