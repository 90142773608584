import axios from "axios";

export const fetchUsers = (callback, page) => {
  const config = {
    method: "GET",
    url: "users",
    params: {page}
  };
  axios.request(config).then((response) => callback(response.data));
};

export const deleteUser = (id, callback) => {
  const config = {
    method: "DELETE",
    url: `users/${id}`,
  };
  axios.request(config).then(() => callback());
};
