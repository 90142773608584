import React from "react";
import "./Label.scss";
import classNames from "classnames";

export const Label = ({ children, required }) => {
  const labelClasses = classNames("label", {
    required: required,
  });
  return <div className={labelClasses}>{children}</div>;
};
