import axios from "axios";

export const getUser = (id, callback) => {
    const config = {
        method: "GET",
        url: `users/${id}`,
    };
    axios.request(config).then((response) => callback(response.data));
};

export const saveUser = (user, callback) => {
    const id = user.id
    const config = {
        method: id ? "PATCH" : "POST",
        url: id ? `users/${id}` : "users",
        data: user,
    };
    axios.request(config).then(() => callback());
};