import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import {Page} from "../../ui/page/Page";

import {ReactComponent as searchIcon} from "../../../assets/icons/search_icon.svg"
import placeholder from "../../../assets/placeholder.jpg";
import {Table} from "../../features/table/Table";

import * as actions from "./SearchActions"
import {DeleteModal} from "../../features/delete-modal/DeleteModal";
import {Image} from "../../ui/image/Image";

export const Search = () => {
    const navigate = useNavigate()
    const params = useParams()

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [searchedResults, setSearchedResults] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    useEffect(() => {
        switch (params.in) {
            case "category":
                actions.fetchCategoriesByQuery((data) => setSearchedResults(data.categories), params.query, page)
                break
            case "tag":
                actions.fetchTagsByQuery((data) => {
                    setTotalPages(data.pages)
                    setSearchedResults(data.tags)
                }, params.query, page)
                break
            case "ingredient":
                actions.fetchIngredientsByQuery((data) => {
                    setSearchedResults(data.ingredients)
                }, params.query, page)
                break
            case "recipe":
                actions.fetchRecipesByQuery((data) => setSearchedResults(data.recipes), params.query, page)
                break
            case "article":
                actions.fetchArticlesByQuery((data) => setSearchedResults(data.articles), params.query, page)
                break
            default:
                setSearchedResults([])
                break
        }
    }, [params.in, params.query, page]);

    const columns = [
        {
            Header: params.in,
            accessor: "name",
            Cell: ({row}) => (
                <div className="name">
                    {params.in !== "tag" && (
                        <Image src={row.original.image?.url} alt={row.original.name}/>
                    )}
                    {row.original.name}
                </div>
            ),
        },
    ];

    const handleEdit = (id) => {
        navigate(`/${params.in}/${id}`)
    }

    const handleDeleteClick = (name, id) => {
        setShowDeleteModal({name, id})
    }

    const deleteFromState = (id) => {
        setSearchedResults(prev => prev.filter(item => item.id !== id))
    }

    const handleDelete = (id) => {
        switch (params.in) {
            case "category":
                actions.deleteCategory(id, () => deleteFromState(id))
                break
            case "tag":
                actions.deleteTag(id, () => deleteFromState(id))
                break
            case "ingredient":
                actions.deleteIngredients(id, () => deleteFromState(id))
                break
            case "recipe":
                actions.deleteRecipe(id, () => deleteFromState(id))
                break
            case "article":
                actions.deleteArticle(id, () => deleteFromState(id))
                break
        }
        setShowDeleteModal(false)
    }

    return (
        <Page icon={searchIcon} title="Search:" subTitle={params.query}>
            <Table columns={columns}
                   data={searchedResults}
                   onEdit={handleEdit}
                   onDelete={handleDeleteClick}
                   currentPage={page}
                   onPageChange={setPage}
                   totalPages={totalPages}
                   isPaginated
            />
            {showDeleteModal && (
                <DeleteModal
                    title={params.in === "category" ? "Categories" : params.in + "s"}
                    element={params.in}
                    toDeleteName={showDeleteModal.name}
                    onClose={() => setShowDeleteModal(false)}
                    onDelete={() => handleDelete(showDeleteModal.id)}
                />
            )}
        </Page>
    );
};