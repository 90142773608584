import React, {useState} from "react";

import "./Navbar.scss";

import {ReactComponent as menuIcon} from "../../../assets/icons/menu_icon.svg";
import {ReactComponent as recipesIcon} from "../../../assets/icons/recipe_icon.svg";
import {ReactComponent as articlesIcon} from "../../../assets/icons/articles_icon.svg";

import {Icon} from "../../ui/icon/Icon";

import classNames from "classnames";
import {NavItem} from "./NavItem";

export const PublicNavbar = ({userSubpages, user_id}) => {
    const [isOpen, setIsOpen] = useState(false);

    const navbarItemsClasses = classNames("items", {
        open: isOpen,
    });


    return (
        <div className="navbar">
            <div className={navbarItemsClasses}>
                <div className="left-content">
                    <NavItem to={`/public/${user_id}/recipes`} icon={recipesIcon} subPage="recipe">
                        Recipes
                    </NavItem>
                    <NavItem to={`/public/${user_id}/articles`} icon={articlesIcon} subPage="article">
                        Articles
                    </NavItem>
                    {userSubpages?.map((subpage) => (
                        <NavItem to={`/public/${user_id}/${subpage.name}`} key={subpage.id}>
                            {subpage.name}
                        </NavItem>
                    ))}
                </div>
            </div>
            <div
                className="menu"
                onClick={() => setIsOpen((prevState) => !prevState)}>
                <Icon icon={menuIcon}/>
            </div>
        </div>
    );
};
