import React, { useState, useEffect } from "react";

import "./Dropdown.scss";
import { DropdownList } from "./DropdownList";

import { ReactComponent as UpIcon } from "../../../assets/icons/up_icon.svg";

export const Dropdown = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (e.target.closest(".dropdown")) return;
      setIsOpen(false);
    };
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <div className="dropdown">
      <button onClick={() => setIsOpen((prev) => !prev)}>
        Settings <UpIcon />
      </button>
      {isOpen && <DropdownList items={items} />}
    </div>
  );
};
