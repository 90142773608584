import React, { useState } from "react";
import { Label } from "../../ui/label/Label";
import { SelectInput } from "../../ui/select/SelectInput";
import { Input } from "../../ui/input/Input";
import { Button } from "../../ui/button/Button";

import "./IngredientsModal.scss";
import { useFormik } from "formik";

import * as yup from "yup";
import _ from "lodash";

const ingredientSchema = yup.object().shape({
  ingredient: yup.object().required("Ingredient is required"),
  amount: yup.number().required("Amount is required"),
});

export const IngredientsModal = ({ onClose, onSave, options, editData }) => {
  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      ingredient: options.find(option => option.value === editData.ingredient?.id),
      amount: editData?.amount || 0,
    },
    validationSchema: ingredientSchema,
    onSubmit: (value) => {
      onSave(editData, {id: editData.id ,amount: value.amount, ingredient: {
          ..._.omit(value.ingredient, "image", "label", "value", "recipeIngredient"),
          name: value.ingredient.label,
          id: value.ingredient.value,
          image_url: value.ingredient.image?.url
        }});
    },
  });

  return (
    <div className="ingredients-modal">
      <form autoComplete="off">
        <div className="modal">
          <span className="title">Ingredient {">"} <span className="option">{editData.ingredient ? editData.ingredient.name : "New"}</span> </span>
          <div className="content">
            <Label required>Ingredient</Label>
            <SelectInput
                id="ingredient"
                value={values.ingredient}
                onChange={(option) => setFieldValue("ingredient", option)}
                options={options}/>
            <Label required>Amount</Label>
            <Input
              value={values.amount}
              id="amount"
              onChange={handleChange}
              type="number"
              variant="number"
            />
          </div>
          <div className="footer">
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmit}>Save</Button>
          </div>
        </div>
      </form>
      <div className="backdrop" onClick={onClose} />
    </div>
  );
};
