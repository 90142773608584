import React from 'react';
import "./Pagination.scss"
import {PaginationButton} from "../../ui/paginationButton/PaginationButton";

export const Pagination = ({totalPages, currentPage, onPageChange}) => {
    return (
        <div className="pagination">
            <PaginationButton canBeClicked={currentPage > 1}
                              onClick={() => onPageChange(currentPage - 1)}>{"<"}</PaginationButton>

            <PaginationButton page={1}
                              currentPage={currentPage}
                              onClick={onPageChange}/>

            {totalPages > 1 && <PaginationButton page={2}
                                                 currentPage={currentPage}
                                                 onClick={onPageChange}/>}

            {totalPages > 4 && (
                <>
                    <PaginationButton canBeClicked={false}>...</PaginationButton>
                    {currentPage > 2 && currentPage < totalPages - 1 && (
                        <>
                            <PaginationButton page={currentPage} currentPage={currentPage} onClick={onPageChange}/>
                            <PaginationButton canBeClicked={false}>...</PaginationButton>
                        </>
                    )}
                </>
            )}


            {totalPages > 3 && <PaginationButton page={totalPages - 1}
                                                 currentPage={currentPage}
                                                 onClick={onPageChange}/>}
            {totalPages > 2 && <PaginationButton page={totalPages}
                                                 currentPage={currentPage}
                                                 onClick={onPageChange}/>}


            <PaginationButton canBeClicked={currentPage < totalPages}
                              onClick={() => onPageChange(currentPage + 1)}>{">"}</PaginationButton>
        </div>
    );
};