import axios from "axios";

export const fetchCategoriesByQuery = (callback, query, page) => {
    const config = {
        method: "GET",
        url: "categories",
        params: {query, page}
    }
    axios.request(config).then((response) => callback(response.data))
}

export const fetchTagsByQuery = (callback, query, page) => {
    const config = {
        method: "GET",
        url: "tags",
        params: {query, page}
    }
    axios.request(config).then((response) => callback(response.data))
}

export const fetchIngredientsByQuery = (callback, query, page) => {
    const config = {
        method: "GET",
        url: "ingredients",
        params: {query, page}
    }
    axios.request(config).then((response) => callback(response.data))
}

export const fetchRecipesByQuery = (callback, query, page) => {
    const config = {
        method: "GET",
        url: "recipes",
        params: {query, page}
    }
    axios.request(config).then((response) => callback(response.data))
}

export const fetchArticlesByQuery = (callback, query, page) => {
    const config = {
        method: "GET",
        url: "articles",
        params: {query, page}
    }

    axios.request(config).then((response) => callback(response.data))
}

export const deleteCategory = (id, callback) => {
    const config = {
        url: `categories/${id}`,
        method: "DELETE",
    };
    axios.request(config).then(() => callback());
};

export const deleteTag = (id, callback) => {
    const config = {
        url: `tags/${id}`,
        method: "DELETE",
    };
    axios.request(config).then(() => callback());
};

export const deleteIngredients = (id, callback) => {
    const config = {
        url: `ingredients/${id}`,
        method: "DELETE",
    };
    axios.request(config).then(() => callback());
};

export const deleteRecipe = (id, callback) => {
    const config = {
        url: `recipes/${id}`,
        method: "DELETE",
    };
    axios.request(config).then(() => callback());
};

export const deleteArticle = (id, callback) => {
    const config = {
        url: `articles/${id}`,
        method: "DELETE",
    };
    axios.request(config).then(() => callback());
};