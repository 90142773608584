import React, {useEffect, useState} from 'react';

import "./UserDisplay.scss"

import avatarPlaceholder from "../../../assets/avatar_placeholder.png";
import {Icon} from "../../ui/icon/Icon";

import {ReactComponent as downIcon} from "../../../assets/icons/down_icon.svg";
import {UserDropdownOptions} from "./UserDropdownOptions";

export const UserDisplay = ({image, username}) => {
    const [showDropdown, setShowDropdown] = useState(false)

    useEffect(() => {
        const closeDropdown = (e) => {
            if(e.target.closest(".user-display")) return
            setShowDropdown(false)
        }
        document.addEventListener("click", closeDropdown)
        return () => document.removeEventListener("click", closeDropdown)
    }, []);

    return (
        <div className='user-display' onClick={() => setShowDropdown(prevState => !prevState)}>
            <span className='content'>
                <img src={image ? image : avatarPlaceholder}/>
                {username}
                <Icon icon={downIcon}/>
            </span>
            {showDropdown && <UserDropdownOptions/>}
        </div>
    );
};