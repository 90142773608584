import React from "react";
import "./Page.scss";
import {Icon} from "../icon/Icon";
import {Button} from "../button/Button";
import classNames from "classnames";

export const Page = ({
                         children,
                         className,
                         title,
                         icon,
                         subTitle,
                         buttonName,
                         buttonIcon,
                         onButtonClick,
                     }) => {
    const pageClasses = classNames("page", {
        [className]: className,
    });
    return (
        <div className={pageClasses}>
          <div className="header">
            <span className="title">
              {icon && <Icon icon={icon} />}
              {title}
              <span>{subTitle && " > "}</span>
              {subTitle && <span className="option">{subTitle}</span>}
            </span>
            {buttonName && (
              <Button type="submit" onClick={onButtonClick} icon={buttonIcon}>
                {buttonName}
              </Button>
            )}
          </div>
        {children}
      </div>
  );
};
