import axios from "axios";

export const getIngredient = (id, callback) => {
    const config = {
        url: `ingredients/${id}`,
        method: "GET",
    };
    axios.request(config).then((response) => {
        callback(response.data);
    });
};

export const saveIngredient = (ingredient, callback) => {
    const id = ingredient.id
    const config = {
        url: id ? `ingredients/${id}` : "ingredients",
        method: id ? "PATCH" : "POST",
        data: {ingredient},
    };
    axios.request(config).then(() => callback());
};