import React, {useEffect, useState} from "react";
import {Page} from "../../ui/page/Page";
import {Table} from "../../features/table/Table";

import {ReactComponent as usersIcon} from "../../../assets/icons/users_icon.svg";
import {useNavigate} from "react-router-dom";

import * as actions from "./UsersActions";
import {DeleteModal} from "../../features/delete-modal/DeleteModal";

import avatarPlaceholder from "../../../assets/avatar_placeholder.png";


const columns = [
    {
        Header: "User",
        accessor: "name",
        Cell: ({row}) => (
            <div className="name">
                {row.original.image ? (
                    <img src={row.original.image.url} alt={row.original.name}/>
                ) : (
                    <img src={avatarPlaceholder}/>
                )}
                {row.original.name} {row.original.surname}
            </div>
        ),
    },
];

export const Users = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const fetchUsers = () => {
        actions.fetchUsers((data) => {
            setUsers(data.users);
            setTotalPages(data.pages)
        }, page);
    }
    useEffect(() => {
        fetchUsers()
    }, [page]);


    const deleteUser = (id) => {
        actions.deleteUser(id, () => {
            setUsers((prev) => prev.filter((user) => user.id !== id));
        });
        setShowDeleteModal(false);
    };

    const editUser = (id) => {
        navigate(`/user/${id}`);
    };

    const handleDeleteClick = (name, id) => {
        setShowDeleteModal({name, id});
    };

    return (
        <Page
            icon={usersIcon}
            title="Users"
            buttonName="+Add new"
            onButtonClick={() => navigate("/user")}>
            <Table
                columns={columns}
                data={users}
                onDelete={handleDeleteClick}
                onEdit={editUser}
                totalPages={totalPages}
                currentPage={page}
                onPageChange={setPage}
                isPaginated
            />
            {showDeleteModal && (
                <DeleteModal
                    title="Users"
                    element="User"
                    toDeleteName={showDeleteModal.name}
                    onDelete={() => deleteUser(showDeleteModal.id)}
                    onClose={() => setShowDeleteModal(false)}
                />
            )}
        </Page>
    );
};
