import axios from "axios";

export const saveTag = (tag, callback) => {
    const id = tag.id;
    const config = {
        url: id ? `tags/${id}` : "tags",
        method: id ? "PATCH" : "POST",
        data: tag,
    };
    axios.request(config).then(() => callback());
};

export const getTag = (id, callback) => {
    const config = {
        url: `tags/${id}`,
        method: "GET",
    };
    axios.request(config).then((response) => {
        callback(response.data);
    });
};