import React from "react";

import "./DropdownList.scss";
import { Link } from "react-router-dom";

export const DropdownList = ({ items }) => {
  return (
    <div className="dropdown-list">
      {items.map((item, i) => (
        <Link to={item.path} key={i} className="item">
          {item.name}
        </Link>
      ))}
    </div>
  );
};
