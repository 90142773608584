import axios from "axios"

export const fetchArticles = (callback, page, sort_by, sort_order) => {
    const config = {
        url: 'articles',
        method: "GET",
        params: {page, sort_by, sort_order}
    }
    axios.request(config).then((response) => callback(response.data))
}

export const deleteArticles = (id, callback) => {
    const config = {
        url: `articles/${id}`,
        method: "DELETE"
    }
    axios.request(config).then(() => callback())
}