import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


import { Page } from "../../ui/page/Page";
import { Table } from "../../features/table/Table";
import { DeleteModal } from "../../features/delete-modal/DeleteModal";
import {Image} from "../../ui/image/Image";

import { ReactComponent as ingredientsIcon } from "../../../assets/icons/ingredients_icon.svg";
import * as actions from "./IngredientsActions";

export const Ingredients = () => {
  const navigate = useNavigate();

  const [ingredients, setIngredients] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const columns = [
    {
      Header: "Ingredient",
      accessor: "name",
      Cell: ({ row }) => (
        <div className="name">
          <Image src={row.original.image?.url } alt={row.original.name} />
          {row.original.name}
        </div>
      ),
    },
    {
      Header: "Units",
      accessor: "unit",
    },
  ];

  const fetchIngredients = () => {
    actions.fetchIngredients((response => {
      setIngredients(response.ingredients)
      setTotalPages(response.pages)
    }), page);
  }

  useEffect(() => {
    fetchIngredients()
  }, [page]);

  const deleteIngredient = (id) => {
    actions.deleteIngredient(id, () => {
      setIngredients((prev) =>
        prev.filter((ingredient) => ingredient.id !== id)
      );
      closeDeleteModal();
    });
  };

  const handleEditClick = (id) => {
    navigate(`/ingredient/${id}`);
  };

  const handleDeleteClick = (name, id) => {
    setShowDeleteModal({ name, id });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <Page
      icon={ingredientsIcon}
      title="Ingredients"
      className="ingredients"
      onButtonClick={() => navigate("/ingredient")}
      buttonName="+ Add new">
      <Table
        columns={columns}
        data={ingredients}
        onEdit={handleEditClick}
        onDelete={handleDeleteClick}
        totalPages={totalPages}
        currentPage={page}
        onPageChange={setPage}
        isPaginated
      />

      {showDeleteModal && (
        <DeleteModal
          title="Ingredients"
          element="Ingredient"
          toDeleteName={showDeleteModal.name}
          onClose={closeDeleteModal}
          onDelete={() => deleteIngredient(showDeleteModal.id)}
        />
      )}
    </Page>
  );
};
