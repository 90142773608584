import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import DOMPurify from 'dompurify'

import "./PublicSubpage.scss"

import * as actions from "./PublicSubpageActions"

export const PublicSubpage = () => {
    const params = useParams()

    const [content, setContent] = useState("")

    useEffect(() => {
        actions.getSubpage(params.id, params.subpage, setContent)
    }, [params.subpage]);

    const createHtmlContent = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    return (
        <div className="public-subpage" dangerouslySetInnerHTML={createHtmlContent(content)} />
    );
};