import React, {useState} from 'react';
import "./DateModal.scss"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {ReactComponent as filtersIcon} from "../../../assets/icons/filters_icon.svg";

import {Form} from "../../ui/form/Form";
import {Label} from "../../ui/label/Label";
import {Button} from "../../ui/button/Button";
import {Icon} from "../../ui/icon/Icon";
import {useFormik} from "formik";

export const DateModal = ({onClose, onSave, defaultStartDate, defaultEndDate}) => {

    const {values, handleSubmit, setFieldValue} = useFormik({
        initialValues: {
            startDate: new Date(defaultStartDate),
            endDate: new Date(defaultEndDate)
        },
        onSubmit: (values) => {
            onSave(values.startDate, values.endDate)
        }
    })

    return (<div className="date-modal">
        <div className="modal">
            <div className="header">
                <Icon icon={filtersIcon}/>
                <span>Filters</span>
            </div>
            <form className="content" id="filters" onSubmit={handleSubmit}>
                <Form>
                    <Label required>From: </Label>
                    <DatePicker showPopperArrow={false} selected={values.startDate}
                                onChange={(date) => setFieldValue("startDate", date)}/>

                    <Label required>To: </Label>
                    <DatePicker showPopperArrow={false} selected={values.endDate}
                                onChange={(date) => setFieldValue("endDate", date)}/>
                </Form>
                <div className="buttons">
                    <Button type="submit">Save</Button>
                    <Button variant="secondary" onClick={onClose}>Cancel</Button>
                </div>
            </form>
        </div>

        <div className="backdrop"/>
    </div>);
};