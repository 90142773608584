export const colors = [
    "#F2453F",
    "#F6991A",
    "#F8C750",
    "#8FC16D",
    "#90E356",
    "#7DCAFE",
    "#353DB2",
    "#887EEF",
    "#D9B8FF",
    "#FEB0A4",
    "#ed96a2",
    "#d47fa5",
    "#eb675a",
    "#9a6b6d",
    "#3a1418",
    "#87754a",
    "#6a7e4d",
    "#408462",
    "#008684",
    "#00c9c7"
]