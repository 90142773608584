import React from "react";

import "./ErrorAlert.scss";

import { ReactComponent as alertIcon } from "../../../assets/icons/alert_icon.svg";
import { Icon } from "../icon/Icon";

export const ErrorAlert = ({ children }) => {
  return (
    <div className="error-alert">
      <Icon icon={alertIcon} />
      <span className="message">{children}</span>
    </div>
  );
};
