import axios from "axios";

export const getReport = (callback, start_date, end_date) => {
    const config = {
        url: "report",
        method: "GET",
        params: {start_date, end_date}
    }

    axios.request(config).then((response) => {
        callback(response.data)
    })
}