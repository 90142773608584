import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";

import "./Searchbar.scss"


import {ReactComponent as searchIcon} from "../../../assets/icons/search_icon.svg"
import {ReactComponent as cancelIcon} from "../../../assets/icons/cancel_icon.svg"

import {Icon} from "../../ui/icon/Icon";
import {Button} from "../../ui/button/Button";
import {Input} from "../../ui/input/Input";
import {Badge} from "../../ui/badge/Badge";

import * as actions from "./SearchbarActions"

export const Searchbar = () => {
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState("")

    const [showDropdown, setShowDropdown] = useState(false)

    const [foundIn, setFoundIn] = useState([])

    useEffect(() => {
        if (searchQuery.length > 0) {
            setShowDropdown(true)
            actions.search(searchQuery, setFoundIn)
        } else {
            setShowDropdown(false)
        }
    }, [searchQuery])

    const handleSearchClick = (where, query) => {
        setShowDropdown(false)
        navigate(`/search/${where.toLowerCase()}/${query}`)
    }

    return (
        <div className="searchbar">
            <Input icon={searchIcon} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                   onFocus={() => searchQuery.length > 0 && setShowDropdown(true)}
                   placeholder="Search"
            />
            <Button variant="icon" icon={cancelIcon} onClick={() => setSearchQuery("")}/>
            {showDropdown && (
                <div className="dropdown">
                    <span className="title">
                        <Icon icon={searchIcon}/> {searchQuery}
                    </span>
                    {foundIn.map(item => (
                        <span key={item} className="search-in" onClick={() => handleSearchClick(item, searchQuery)}>
                            {searchQuery}
                            <Badge>in:{item}</Badge>
                        </span>
                    ))}
                </div>
            )}
        </div>
    )
}