import React from "react";

import "./Textarea.scss";
import classNames from "classnames";

export const Textarea = ({ value, onChange, id, type, error, placeholder }) => {
  const textareaClasses = classNames("textarea", {
    error: error,
  });
  return (
    <textarea
      value={value}
      onChange={onChange}
      id={id}
      type={type}
      placeholder={placeholder}
      className={textareaClasses}
    />
  );
};
