import React, {useEffect, useState} from 'react';
import "./PublicRecipes.scss"

import {Page} from "../../../ui/page/Page";

import * as actions from "./PublicRecipesActions"
import {useNavigate, useParams} from "react-router-dom";
import {Image} from "../../../ui/image/Image";

export const PublicRecipes = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [recipes, setRecipes] = useState([])

    useEffect(() => {
        actions.fetchRecipes(params.id, (recipes) => {
            setRecipes(recipes)
        })
    }, []);


    return (
        <Page className="public-recipes" title="All recipes">
            <div className="recipes">
                {recipes.map(recipe => (
                    <div className="recipe" key={recipe.id} onClick={() => navigate(`${recipe.id}`)}>
                        <Image src={recipe.image?.url}/>
                        <span className="title">{recipe.name}</span>
                    </div>
                ))}
            </div>
        </Page>
    );
};
