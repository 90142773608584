import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as categoriesIcon } from "../../../assets/icons/categories_icon.svg";

import { Table } from "../../features/table/Table";
import { DeleteModal } from "../../features/delete-modal/DeleteModal";
import { Page } from "../../ui/page/Page";
import { Image } from "../../ui/image/Image";

import * as actions from "./CategoriesActions";

export const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const navigate = useNavigate();

  const columns = [
    {
      Header: "Category",
      accessor: "name",
      Cell: ({ row }) => (
        <div className="name">
          <Image src={row.original.image?.url} alt={row.original.name} />
          {row.original.name}
        </div>
      ),
    },
  ];

  const fetchCategories = () => {
    actions.fetchCategories((response) => {
      setCategories(response.categories)
      setTotalPages(response.pages)
    }, page);
  };

  useEffect(() => {
    fetchCategories();
  }, [page]);

  const deleteCategory = (id) => {
    actions.deleteCategory(id, () => {
      setCategories((prev) => prev.filter((category) => category.id !== id));
      closeDeleteModal();
    });
  };

  const editCategory = (id) => {
    navigate(`/category/${id}`);
  };

  const handleDeleteClick = (name, id) => {
    const newContentModal = {
      name: name,
      id: id,
    };
    setShowDeleteModal(newContentModal);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <Page
      className="categories"
      icon={categoriesIcon}
      title="Categories"
      buttonName="+ Add new"
      onButtonClick={() => navigate("/category")}>
      <Table
          data={categories}
          columns={columns}
          onEdit={editCategory}
          onDelete={handleDeleteClick}
          totalPages={totalPages}
          currentPage={page}
          onPageChange={setPage}
          isPaginated
      />
      {showDeleteModal && (
        <DeleteModal
          element="category"
          title="Categories"
          toDeleteName={showDeleteModal.name}
          onDelete={() => deleteCategory(showDeleteModal.id)}
          onClose={closeDeleteModal}
        />
      )}
    </Page>
  );
};
