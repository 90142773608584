import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";

import {Page} from "../../ui/page/Page";
import {Form} from "../../ui/form/Form";
import {Label} from "../../ui/label/Label";
import {Input} from "../../ui/input/Input";
import {SelectInput} from "../../ui/select/SelectInput";
import {Textarea} from "../../ui/textarea/Textarea";
import {PhotoInput} from "../../ui/photoInput/PhotoInput";
import {Table} from "../../features/table/Table";
import {Button} from "../../ui/button/Button";
import {Image} from "../../ui/image/Image";
import {IngredientsModal} from "../../features/ingredientsModal/IngredientsModal";

import {ReactComponent as recipeIcon} from "../../../assets/icons/recipe_icon.svg";

import * as actions from "./RecipeActions"
import * as yup from "yup";
import _ from "lodash";


const recipeSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    preparation_time: yup.number().required("Preparation time is required"),
    category_id: yup.number().required("Category is required"),
});

const columns = [
    {
        Header: "Photo",
        accessor: "ingredient.image_url",
        Cell: ({value}) => <Image src={value} alt="ingredient image" />,
    },
    {
        Header: "Ingredient",
        accessor: "ingredient.name",
    },
    {
        Header: "Amount",
        accessor: "amount",
        Cell: ({value}) => <span>{parseFloat(value)}</span>
    },
    {
        Header: "Unit",
        accessor: "ingredient.unit",
    },
];

export const Recipe = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [prevName, setPrevName] = useState("");

    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [tagsOptions, setTagsOptions] = useState([]);
    const [ingredientsOptions, setIngredientsOptions] = useState([]);

    const [ingredientToEdit, setIngredientToEdit] = useState(null);

    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: {
            name: "",
            description: "",
            preparation_time: "",
            category_id: null,
            tags: [],
            image: null,
            recipe_ingredients: [],
            ingredient_to_destroy: [],
        },
        validationSchema: recipeSchema,
        onSubmit: (values) => {
            const recipe_ingredients_attributes = values.recipe_ingredients.concat(values.ingredient_to_destroy || [])
            actions.saveRecipe({
                ..._.omit(values, "image", "tags", "recipe_ingredients", "ingredient_to_destroy"),
                photo_id: values.image?.id || null,
                tag_ids: values.tags.map(tag => tag.id),
                recipe_ingredients_attributes: recipe_ingredients_attributes.map(recipeIngredient => ({
                    id: recipeIngredient.id || null,
                    ingredient_id: recipeIngredient.ingredient?.id,
                    amount: recipeIngredient.amount,
                    _destroy: recipeIngredient._destroy || null
                }))
            }, () => navigate("/recipes"))
        },
    });

    const mapIdName = (data) => data.map(item => ({
        ..._.omit(item, "id", "name"), value: item.id, label: item.name
    }))
    const mapValueLabel = (data) => data.map(item => ({
        ..._.omit(item, "value", "label"), id: item.value, name: item.label
    }))

    useEffect(() => {
        actions.fetchData((contentForRecipes) => {
            setCategoriesOptions(mapIdName(contentForRecipes.categories))
            setTagsOptions(mapIdName(contentForRecipes.tags))
            setIngredientsOptions(mapIdName(contentForRecipes.ingredients))
        })

        if (params.id) {
            actions.getRecipe(params.id, (recipe) => {
                setPrevName(recipe.name)
                setValues(recipe)
            });
        }
    }, []);

    const addOrEditIngredient = (newIngredient, index) => {
        if (index !== -1) {
            return values.recipe_ingredients.map((item, i) =>
                i === index ? newIngredient : item
            )
        } else {
            return [...values.recipe_ingredients, newIngredient]
        }
    }

    const handleSaveIngredient = (toEdit, newIngredient) => {
        const index = values.recipe_ingredients.findIndex(item => item.ingredient.id === toEdit.ingredient?.id)
        setFieldValue("recipe_ingredients", addOrEditIngredient(newIngredient, index))
        setIngredientToEdit(null);
    };

    const handleDeleteIngredient = (recipeIngredient) => {
        setFieldValue("recipe_ingredients", values.recipe_ingredients.filter(ingredient => ingredient.ingredient.id !== recipeIngredient.ingredient.id))
        if (recipeIngredient.id) {
            setFieldValue("ingredient_to_destroy", [{id: recipeIngredient.id, _destroy: 1}])
        }
    };
    const handleEditIngredient = (recipeIngredient) => {
        const index = values.recipe_ingredients.findIndex(item => item.ingredient.id === recipeIngredient.ingredient.id)
        setIngredientToEdit(recipeIngredient);
    };
    const handleIngredientAddButton = (e) => {
        e.preventDefault();
        setIngredientToEdit({});
    };

    return (
        <Page
            className="recipe"
            title="Recipes"
            icon={recipeIcon}
            subTitle={params.id ? `Edit - ${prevName}` : "New "}
            buttonName="Save"
            onButtonClick={handleSubmit}>
            <form
                autoComplete="off"
                id="category"
                onSubmit={(e) => e.preventDefault()}>
                <Form>
                    <Label required>Name</Label>
                    <Input
                        error={errors.name && touched.name}
                        value={values.name}
                        placeholder={errors.name && touched.name && errors.name}
                        onChange={handleChange}
                        id="name"
                        type="text"
                    />

                    <Label required>Description</Label>
                    <Textarea
                        error={errors.description && touched.description}
                        placeholder={errors.description && touched.description && errors.description}
                        value={values.description}
                        onChange={handleChange}
                        id="description"
                        type="text"
                    />

                    <Label required>Preparation Time</Label>
                    <div style={{
                        width: "35px",
                        display: "flex",
                        alignItems: "flex-end",
                        gap: "8px",
                        padding: "4px 0",
                        boxSizing: "border-box"
                    }}>
                        <Input
                            error={errors.preparation_time && touched.preparation_time}
                            value={values.preparation_time}
                            onChange={handleChange}
                            id="preparation_time"
                            type="number"
                            variant="small-number"
                        />
                        <span style={{marginBottom: "4px"}}>min</span>
                    </div>

                    <Label required>Category</Label>
                    <SelectInput
                        options={categoriesOptions}
                        value={
                            categoriesOptions &&
                            categoriesOptions.find((category) => category.value === values.category_id)
                        }
                        onChange={(option) => setFieldValue("category_id", option.value)}
                        error={errors.category_id && touched.category_id}
                        placeholder={errors.category_id && touched.category_id && errors.category_id}
                    />

                    <Label>Tag</Label>
                    <SelectInput
                        options={tagsOptions}
                        value={mapIdName(values.tags)}
                        onChange={(option) => setFieldValue("tags", mapValueLabel(option))}
                        isMulti
                    />

                    <Label>Ingredients</Label>
                    <div>
                        <Table columns={columns}
                               data={values.recipe_ingredients}
                               onAddClick={handleIngredientAddButton}
                               onEdit={handleEditIngredient}
                               onDelete={handleDeleteIngredient}
                               variant="ingredients"
                               footerContent={<Button variant="text" onClick={handleIngredientAddButton}>+Add
                                   new</Button>}
                        />
                    </div>
                    <Label>Photo</Label>
                    <PhotoInput
                        onPhotoUpload={setFieldValue}
                        value={values.image?.url}
                    />
                </Form>
            </form>
            {ingredientToEdit !== null && (
                <IngredientsModal
                    onClose={() => setIngredientToEdit(null)}
                    onSave={handleSaveIngredient}
                    options={ingredientsOptions.filter(ingredient => !values.recipe_ingredients.find(e => e.ingredient?.id === ingredient.value) || ingredient.value === ingredientToEdit.ingredient?.id)}
                    editData={ingredientToEdit}
                />
            )}
        </Page>
    );
};
