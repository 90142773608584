import axios from "axios";

export const fetchRecipes = (callback, filters, page, sort_by, sort_order) => {
  const config = {
    url: "recipes",
    method: "GET",
    params: {...filters, page, sort_by, sort_order}
  };
  axios.request(config).then((response) => callback(response.data));
};
export const deleteRecipe = (id, callback) => {
  const config = {
    url: `recipes/${id}`,
    method: "DELETE",
  };
  axios.request(config).then(() => callback());
};

export const fetchData = (callback) => {
  const endpoints = ['categories', 'tags', 'ingredients']
  axios.all(endpoints.map(endpoint => axios.get(endpoint))).then(axios.spread(({data: {categories}}, {data: {tags}}, {data: {ingredients}}) => {
    callback({categories, tags, ingredients})
  }))
}