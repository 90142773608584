import axios from "axios";

export const fetchIngredients = (callback, page) => {
  const config = {
    url: "ingredients",
    method: "GET",
    params: {page}
  };
  axios.request(config).then((response) => {
    callback(response.data);
  });
};


export const deleteIngredient = (id, callback) => {
  const config = {
    url: `ingredients/${id}`,
    method: "DELETE",
  };
  axios.request(config).then(() => callback());
};
