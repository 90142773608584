import React, {useEffect, useState} from 'react';
import "./Article.scss"
import {useNavigate, useParams} from "react-router-dom";

import {ReactComponent as articlesIcon} from "../../../assets/icons/articles_icon.svg";

import {Page} from "../../ui/page/Page";
import {Form} from "../../ui/form/Form";
import {Label} from "../../ui/label/Label";
import {Input} from "../../ui/input/Input";
import {TextEditor} from "../../ui/textEditor/TextEditor";
import {Button} from "../../ui/button/Button";

import {useFormik} from "formik";
import {convertToRaw, EditorState, ContentState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import * as actions from "./ArticleActions";
import {PhotoInput} from "../../ui/photoInput/PhotoInput";
import _ from "lodash";
import * as yup from "yup";

const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    image: yup
        .object()
        .nullable(true)
        .shape({
            file: yup
                .mixed()
                .nullable(true)
                .test("fileType", "The file must be an image", (value) => {
                    if (value) {
                        const supportedFormats = ["image/jpg", "image/jpeg", "image/png"];
                        return supportedFormats.includes(value.type);
                    }
                    return true;
                })
                .test("fileSize", "The file is too large", (value) => {
                    if (value) {
                        return value.size <= 31457280;
                    }
                    return true;
                }),
        }),
    content: yup.string().required("Content is required")
});

export const Article = () => {
    const navigate = useNavigate()
    const params = useParams()

    const [prevName, setPrevName] = useState("")
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

    const {values, handleSubmit, handleChange, setFieldValue, setValues, errors, touched} = useFormik({
        initialValues: {
            name: "",
            content: "",
            image: null
        },
        onSubmit: (values) => {
            actions.saveArticle({
                ..._.omit(values, "image"),
                photo_id: values.image?.id || null
            }, () => navigate("/articles"))
        },
        validationSchema
    })

    useEffect(() => {
        setFieldValue("content", convertContentToHtml())
    }, [editorState]);

    const convertContentToHtml = () => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }

    useEffect(() => {
        if (params.id) {
            actions.getArticle(params.id, (article) => {
                setPrevName(article.name)
                setValues(article)
                const blocksFromHtml = htmlToDraft(article.content)
                const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks)
                setEditorState(EditorState.createWithContent(contentState))
            })

        }
    }, []);

    return (
        <Page className="article" icon={articlesIcon} title="Articles" subTitle={params.id ? prevName : "new Article"}>
            <form onSubmit={handleSubmit}>
                <Form>
                    <Label required>Name</Label>
                    <Input placeholder={errors.name && touched.name ? errors.name : "Name"}
                           id="name"
                           value={values.name}
                           onChange={handleChange}
                           error={errors.name && touched.name}
                    />
                    <Label>Photo</Label>
                    <PhotoInput value={values.image?.url} onPhotoUpload={setFieldValue} />
                    <Label required>Content</Label>
                    <TextEditor value={editorState}
                                onChange={setEditorState}
                    />
                </Form>
                <div className="footer">
                    <Button type="submit">Save</Button>
                </div>
            </form>
        </Page>
    );
};