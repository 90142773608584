import React, {useEffect, useState} from 'react';

import "./PublicArticle.scss"

import {Page} from "../../../ui/page/Page";
import {useParams} from "react-router-dom";
import {Image} from "../../../ui/image/Image";
import {Badge} from "../../../ui/badge/Badge";
import dayjs from "dayjs";

import * as actions from "./PublicArticleActions"
import DOMPurify from "dompurify";

export const PublicArticle = () => {
    const params = useParams()
    const [article, setArticle] = useState([])

    useEffect(() => {
        actions.getArticle(params.id, params.article_id, (article) => {
            setArticle(article)
        })
    }, []);

    const createHtmlContent = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    return (
        <Page className="public-article" title="You're reading">
            <div className="content">
                <div className="article">
                    <div className="header">
                        <span className="name">{article.name}</span>

                    </div>
                    <div className="details">
                        <div className="creator">
                            <Image src={article.creator?.image?.url}/>
                            <span>BY {article.creator?.name} {article.creator?.surname}</span>
                        </div>
                        <span className="created_at">{dayjs(article.created_at).format("MMMM DD, YYYY")}</span>
                    </div>
                    <Image src={article.image?.url}/>
                    <div className="content" dangerouslySetInnerHTML={createHtmlContent(article.content)}></div>
                </div>
            </div>
        </Page>
    );
};
