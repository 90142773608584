import React, {useEffect, useState} from 'react';
import "./DoughnutChart.scss"
import {Doughnut} from "react-chartjs-2";

import Chart from "chart.js/auto";
import {CategoryScale} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {useNavigate} from "react-router-dom";

Chart.register(CategoryScale)
Chart.register(ChartDataLabels)

export const DoughnutChart = ({reports = []}) => {
    const navigate = useNavigate()
    const [chartData, setChartData] = useState()

    useEffect(() => {
        if (reports.length > 0) {
            setChartData({
                labels: reports.map(report => report.category.name),
                datasets: [{
                    label: "Recipes",
                    data: reports.map(report => report.recipes.reduce((recipesCount, recipe) => recipesCount + recipe.count, 0)),
                    backgroundColor: reports.map(report => report.color),
                    spacing: 0,
                    borderWidth: 0
                }],
            })
        }
    }, [reports]);

    return (
        <div className="doughnut-chart">
            <div className="header">
                <span className="count">{reports.reduce((total_count, report) => total_count + report.recipes.reduce((recipesCount, recipe) => recipesCount + recipe.count, 0), 0)}</span>
                <span className="title">Recipes</span>
            </div>
            {chartData && <Doughnut data={chartData} options={{
                plugins: {
                    legend: {
                        position: "bottom",
                        labels: {usePointStyle: true, boxWidth: 8, boxHeight: 8}
                    },
                    datalabels: {display: true, color: "white", formatter: (value) => { return value > 0 ? value: '' }},
                },
                cutout: "60%",
                radius: "70%",
                onClick(event, element) {
                    const index = element[0].index
                    navigate(`/recipes?category_id=${reports[index].category.id}`)
                }
            }}
            />}
        </div>
    );
};