import React from "react";
import { Button } from "../../ui/button/Button";

import "./DeleteModal.scss";

export const DeleteModal = ({
  title,
  element,
  toDeleteName,
  onDelete,
  onClose,
}) => {
  return (
    <div className="delete-modal">
      <div className="modal">
        <span className="title">
          {title} {">"} <strong>{toDeleteName}</strong>
        </span>
        <div className="content">
          <span>
            Are you sure you want to delete {element}:{" "}
            <strong>{toDeleteName}</strong>
          </span>
        </div>
        <div className="footer">
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onDelete}>Delete</Button>
        </div>
      </div>
      <div className="backdrop" onClick={onClose} />
    </div>
  );
};
