import React, {useEffect, useState} from 'react';
import "./BarChart.scss"

import {Bar} from "react-chartjs-2";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

export const BarChart = ({reports, startDate, endDate}) => {
    const navigate = useNavigate()
    const [chartData, setChartData] = useState()

    useEffect(() => {
        if (reports.length > 0){
            const dateArray = []
            let date = dayjs(startDate)
            const stopDate = dayjs(endDate)

            while (date.isBefore(stopDate)) {
                dateArray.push(date)
                date = date.add(1, 'month')
            }

            setChartData({
                labels: dateArray.map(date => dayjs(date).format("MMMM")),
                datasets: reports.map(report => ({
                    label: report.category.name,
                    data: dateArray.map(date => (
                        report.recipes.find(recipeInDate => dayjs(recipeInDate.date).isSame(date, 'month'))?.count || 0
                    )),
                    backgroundColor: report.color,
                    spacing: 0,
                    borderWidth: 0
                }))
            })
        }
    }, [reports]);

    return (
        <div className="bar-chart">
            <div className="header">
                <span className="date">From: {dayjs(startDate).format("MM/DD/YYYY")} To: {dayjs(endDate).format("MM/DD/YYYY")} </span>
                <span className="count">{reports.reduce((total_count, report) => total_count + report.recipes.reduce((recipesCount, recipe) => recipesCount + recipe.count, 0), 0)}</span>
                <span className="title">Recipes</span>
            </div>
            <div className="chart">
                {chartData && <Bar data={chartData} options={{
                    plugins: {
                        legend: {
                            position: "bottom",
                            labels: {usePointStyle: true, boxWidth: 8, boxHeight: 8}
                        },
                        datalabels: {display: false},

                    },
                    onClick(event, element) {
                        const index = element[0].datasetIndex
                        navigate(`/recipes?category_id=${reports[index].category.id}`)
                    },
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            suggestedMax: 4,
                            ticks: {
                                stepSize: 1
                            }
                        }
                    }
                }}/>}
            </div>
        </div>
    );
};