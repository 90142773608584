import React, {useEffect, useState} from "react";

import "./Login.scss";

import loginImage from "../../../assets/login-page.png";
import {Input} from "../../ui/input/Input";
import {Label} from "../../ui/label/Label";
import {Button} from "../../ui/button/Button";
import {ErrorAlert} from "../../ui/errorAlert/ErrorAlert";
import {useFormik} from "formik";

import * as yup from "yup"

import * as actions from "./LoginActions"
import {useNavigate} from "react-router-dom";


const loginSchema = yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required")
})

export const Login = () => {
    const [loginError, setLoginError] = useState(false)
    const navigate = useNavigate();
    const {
        values,
        handleChange,
        handleSubmit, errors,
        touched
    } = useFormik({
        validationSchema: loginSchema,
        initialValues: {
            email: "",
            password: ""
        },
        onSubmit: (values) => {
            actions.login(values, () => setLoginError(true), (authenticatedUser) => {
                localStorage.setItem("auth", JSON.stringify(authenticatedUser))
                if(authenticatedUser.user.role === "admin"){
                    navigate("/users")
                }else{
                    navigate("/recipes")
                }
            })
        }
    })

    useEffect(() => {
        localStorage.clear('auth')
    }, []);

    return (
        <div className="login">
            <img src={loginImage} className="image"/>
            <div className="right">
                <form id="login" onSubmit={handleSubmit}>
                    <h2>NICE TO SEE YOU!</h2>
                    {loginError && (
                        <ErrorAlert>
                            The email or password are incorrect. Please try again.
                        </ErrorAlert>
                    )}
                    <Label>Login</Label>
                    <Input error={errors.email && touched.email}
                           placeholder={errors.email && touched.email ? errors.email : "E-mail"}
                           id="email"
                           value={values.email}
                           onChange={handleChange}
                    />
                    <Label>Password</Label>
                    <Input
                        error={errors.password && touched.password}
                        id="password"
                        type="password"
                        placeholder={errors.password && touched.password ? errors.password : "Password"}
                        value={values.password}
                        onChange={handleChange}
                    />
                    <Button type="submit">Login</Button>
                </form>
            </div>
        </div>
    );
};
