import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { client } from "scrumship-client";

import { BrowserRouter } from "react-router-dom";

import "./index.scss";

import appConfig from "./config/applicationConfiguration";

axios.defaults.baseURL = appConfig.apiUrl;
axios.defaults.headers["Accept"] = "application/json";
client.log("lXruWYU");

axios.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem("auth"))?.token
    if(token){
        config.headers["authorization"] = token
    }
    return config
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
