import React, { useEffect, useState } from "react";
import { Page } from "../../ui/page/Page";

import { ReactComponent as tagsIcon } from "../../../assets/icons/tags_icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "../../ui/form/Form";
import { useFormik } from "formik";
import { Label } from "../../ui/label/Label";
import { Input } from "../../ui/input/Input";
import { Error } from "../../ui/error/Error";

import * as yup from "yup";
import * as actions from "./TagActions";

const tagSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
});

export const Tag = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [prevName, setPrevName] = useState("");

  const { values, handleChange, handleSubmit, errors, touched, setValues } =
    useFormik({
      initialValues: {
        name: "",
      },
      validationSchema: tagSchema,
      onSubmit: (values) => {
        actions.saveTag( values, () => {
          navigate("/tags");
        });
      },
    });

  useEffect(() => {
    if (params.id) {
      actions.getTag(params.id, (data) => {
        setValues(data);
        setPrevName(data.name);
      });
    }
  }, []);
  return (
    <Page
      icon={tagsIcon}
      title="Tags"
      subTitle={params.id ? `Edit - ${prevName}` : "New"}
      buttonName="Save"
      onButtonClick={handleSubmit}>
      <form autoComplete="off" id="tag">
        <Form>
          <Label required>Name</Label>
          <Input
            name="name"
            id="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name && touched.name}
          />
          {errors.name && touched.name && <Error>{errors.name}</Error>}
        </Form>
      </form>
    </Page>
  );
};
