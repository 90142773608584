import React, {useEffect, useState} from 'react';
import "./PublicArticles.scss"

import {Page} from "../../../ui/page/Page";

import * as actions from "./PublicArticlesActions"
import {useNavigate, useParams} from "react-router-dom";
import {Image} from "../../../ui/image/Image";
import {Badge} from "../../../ui/badge/Badge";

import dayjs from "dayjs";
import {Button} from "../../../ui/button/Button";

import {convertFromHTML, ContentState} from "draft-js"
import {Article} from "./Article";

export const PublicArticles = () => {
    const params = useParams()
    const navigate = useNavigate()

    const [articles, setArticles] = useState([])

    const [filterDate, setFilterDate] = useState(dayjs())

    useEffect(() => {
        actions.fetchArticles(params.id, filterDate, (articles) => {
            const column1 = articles.filter((article, i) => i % 3 === 0)
            const column2 = articles.filter((article, i) => i % 3 === 1)
            const column3 = articles.filter((article, i) => i % 3 > 1)
            setArticles({column1, column2, column3})
        })

    }, [filterDate]);

    return (
        <Page className="public-articles" title="All recipes">
            <div className="content">
                <Badge>{filterDate.format("YYYY")}</Badge>
                <div className="articles">
                    <div className="column">
                        {articles.column1?.map(article => (
                            <Article article={article} />
                        ))}
                    </div>

                    <div className="column">
                        {articles.column2?.map(article => (
                            <Article article={article} />
                        ))}
                    </div>

                    <div className="column">
                        {articles.column3?.map(article => (
                            <Article article={article} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="navigation">
                <Button variant="badge"
                        onClick={() => setFilterDate(prevDate => prevDate.subtract(1, "year"))}
                >
                    {"<"} {filterDate.subtract(1, "year").format("YYYY")}
                </Button>
                <Button variant="badge"
                        onClick={() => setFilterDate(prevDate => prevDate.add(1, "year"))}
                >
                    {filterDate.add(1, "year").format("YYYY")} {">"}
                </Button>
            </div>
        </Page>
    );
};
