import axios from "axios"

export const fetchSubpages = (callback) => {
    const config = {
        url: "subpages",
        method: "GET"
    }
    axios.request(config).then((response) => callback(response.data))
}

export const deleteSubpage = (id, callback) => {
    const config = {
        url: `subpages/${id}`,
        method: "DELETE"
    }
    axios.request(config).then(() => callback())
}

export const updateSubpage = (subpage) => {
    const id = subpage.id
    const config = {
        url: `subpages/${id}`,
        method: "PATCH",
        data: {subpage}
    }
    axios.request(config)
}