import React, {useEffect, useState} from 'react';

import * as actions from "./PublicActions"
import {PublicNavbar} from "../../features/navbar/PublicNavbar";
import {Outlet, useParams} from "react-router-dom";
export const Public = () => {
    const params = useParams()

    const [subpages, setSubpages] = useState()

    useEffect(() => {
        actions.fetchSubpages(params.id, (subpages) => setSubpages(subpages))
    }, []);
    return (
        <div className="public">
            <PublicNavbar userSubpages={subpages} user_id={params.id} />
            <Outlet />
        </div>
    );
};