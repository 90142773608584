import React, {useEffect, useState} from 'react';

import "./Reports.scss"

import {ReactComponent as reportsIcon} from "../../../assets/icons/reports_icon.svg";
import {ReactComponent as filtersIcon} from "../../../assets/icons/filters_icon.svg";

import {Page} from "../../ui/page/Page";

import {DoughnutChart} from "../../features/./doughnutChart/DoughnutChart";
import {Table} from "../../features/table/Table";

import * as actions from "./ReportsActions"
import {BarChart} from "../../features/./barChart/BarChart";
import {DateModal} from "../../features/dateModal/DateModal";
import dayjs from "dayjs";
import _ from "lodash";
import {colors} from "./Colors";

const columns = [
    {
        Header: "Color",
        accessor: "color",
        Cell: ({row}) => (
            <span className="dot" style={{backgroundColor: row.original.color}}/>
        )
    },
    {
        Header: "Category",
        accessor: "category.name"
    },
    {
        Header: "Amount",
        accessor: "recipes.count",
        Cell: ({row}) => (
            <span>{row.original.recipes.reduce((count, recipe) => count + recipe.count, 0)}</span>
        )
    },
    {
        Header: "Percent",
        accessor: "percent",
        Cell: ({row}) => (
            <span>{row.original.percent || "0.0"}%</span>
        )
    }
]

export const Reports = () => {
    const [report, setReport] = useState([])

    const [showFiltersModal, setShowFiltersModal] = useState(false)

    const [startDate, setStartDate] = useState(dayjs().startOf("year"))
    const [endDate, setEndDate] = useState(dayjs().endOf('year'))

    useEffect(() => {
        actions.getReport((reports) => {
            const total_recipes_count = reports.reduce((total_count, report) => total_count + report.recipes.reduce((recipesCount, recipe) => recipesCount + recipe.count, 0), 0);
            setReport(reports.map((report, i) => ({
                ...report,
                color: colors[i % colors.length],
                percent: ((report.recipes.reduce((recipesCount, recipe) => recipesCount + recipe.count, 0) / total_recipes_count) * 100).toFixed(1)
            })))
        }, startDate, endDate)
    }, [startDate, endDate]);

    const handleFiltersChange = (startDate, endDate) => {
        setStartDate(dayjs(startDate))
        setEndDate(dayjs(endDate))
        setShowFiltersModal(false)
    }

    return (
        <Page icon={reportsIcon} title="Reports"
              buttonName="Filters"
              buttonIcon={filtersIcon}
              className="reports"
              onButtonClick={() => setShowFiltersModal(true)}>
            <div className="content">
                <div className="top">
                    <DoughnutChart reports={report}/>
                    <Table data={report} columns={columns} variant="reports"/>
                </div>
                <BarChart reports={report} startDate={startDate} endDate={endDate}/>
            </div>
            {showFiltersModal && <DateModal onSave={handleFiltersChange} onClose={() => setShowFiltersModal(false)}
                                            defaultStartDate={startDate} defaultEndDate={endDate}/>}
        </Page>
    );
};