import axios from "axios";

export const fetchCategories = (callback, page) => {
  const config = {
    url: "categories",
    method: "GET",
    params: {page}
  };
  axios.request(config).then((response) => {
    callback(response.data);
  });
};


export const deleteCategory = (id, callback) => {
  const config = {
    url: `categories/${id}`,
    method: "DELETE"
  };
  axios.request(config).then(() => callback()).catch(() => {
    alert("Cannot delete category (It is used in recipe)")
  });
};
