import "./PaginationButton.scss";
export const PaginationButton = ({
                                     page,
                                     children,
                                     canBeClicked=true,
                                     currentPage=null,
                                     onClick
                                 }) => {
    return (
        <button
            className={`pagination-btn ${page === currentPage ? "current" : null}`}
            disabled={!canBeClicked}
            onClick={() => onClick(page)}>
            {children || page}
        </button>
    );
};
