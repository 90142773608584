import axios from "axios"

export const saveSubpage = (subpage, callback) => {
    const id = subpage.id
    const config = {
        url: id ? `subpages/${id}` : "subpages",
        method: id ? "PATCH" : "POST",
        data: {subpage}
    }
    axios.request(config).then(() => callback())
}

export const getSubpage = (id, callback) => {
    const config = {
        url: `subpages/${id}`,
        method: "GET"
    }

    axios.request(config).then((response) => callback(response.data))
}