import React from "react";

import "./Button.scss";
import classNames from "classnames";
import { Icon } from "../icon/Icon";

export const Button = ({ children, onClick, variant, icon, type }) => {
  const buttonClasses = classNames("button", { [variant]: variant });
  return (
    <button className={buttonClasses} onClick={onClick && onClick} type={type}>
      {icon && <Icon icon={icon} />}
      {children}
    </button>
  );
};
