import React, {useEffect, useState} from "react";
import {Page} from "../../ui/page/Page";

import {ReactComponent as ingredientsIcon} from "../../../assets/icons/ingredients_icon.svg";
import {useNavigate, useParams} from "react-router-dom";
import {Form} from "../../ui/form/Form";
import {Label} from "../../ui/label/Label";
import {Input} from "../../ui/input/Input";
import {PhotoInput} from "../../ui/photoInput/PhotoInput";
import {SelectInput} from "../../ui/select/SelectInput";
import {useFormik} from "formik";

import * as actions from "./IngredientActions"

import * as yup from "yup";
import {Error} from "../../ui/error/Error";
import _ from "lodash";

const units = [
    {value: "Grams", label: "Grams"},
    {value: "Kilograms", label: "Kilograms"},
    {value: "Liters", label: "Liters"},
    {value: "Milliliters", label: "Milliliters"},
];

const ingredientSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    unit: yup.string().required("Unit is required"),
    image: yup
        .object()
        .nullable(true)
        .shape({
            file: yup
                .mixed()
                .test("fileType", "The file must be an image", (value) => {
                    if (value) {
                        const supportedFormats = ["image/jpg", "image/jpeg", "image/png"];
                        return supportedFormats.includes(value.type);
                    }
                    return true;
                })
                .test("fileSize", "The file is too large", (value) => {
                    if (value) {
                        return value.size <= 31457280;
                    }
                    return true;
                }),
        }),
});

export const Ingredient = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [prevName, setPrevName] = useState("");

    const {
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        setValues,
    } = useFormik({
        initialValues: {
            name: "",
            unit: "",
            image: null,
        },
        validationSchema: ingredientSchema,
        onSubmit: (values) => {
            actions.saveIngredient({
                ..._.omit(values, "image"),
                photo_id: values.image?.id || null,
            }, () => navigate("/ingredients"))
        },
    });

    useEffect(() => {
        if (params.id) {
            actions.getIngredient(params.id, (data) => {
                setPrevName(data.name);
                setValues(data);
            });
        }
    }, []);

    return (
        <Page
            icon={ingredientsIcon}
            title="Ingredients"
            subTitle={params.id ? `Edit - ${prevName}` : "New"}
            className="ingredient"
            buttonName="Save"
            onButtonClick={handleSubmit}>
            <form autoComplete="off" id="ingredient">
                <Form>
                    <Label required>Name</Label>
                    <Input
                        id="name"
                        type="text"
                        onChange={handleChange}
                        value={values.name}
                        error={errors.name && touched.name}
                    />
                    <Label required>Unit</Label>
                    <SelectInput
                        options={units}
                        value={units.find((unit) => unit.value === values.unit)}
                        onChange={(option) => setFieldValue("unit", option.value)}
                    />
                    {errors.unit && touched.unit ? <Error>{errors.unit}</Error> : null}
                    <Label>Photo</Label>
                    <PhotoInput
                        value={values.image?.url}
                        onPhotoUpload={setFieldValue}
                    />
                    {errors.image && touched.image ? <Error>{errors.image}</Error> : null}
                </Form>
            </form>
        </Page>
    );
};
