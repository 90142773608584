import React from "react";

import "./Checkbox.scss";

export const Checkbox = ({ id, onChange, checked }) => {
  const handleChange = (e) => {
    onChange(id, e.target.checked);
  };
  return (
    <label className="checkbox">
      <input id={id} type="checkbox" onChange={handleChange} checked={checked} />
      <div className="check" />
    </label>
  );
};
