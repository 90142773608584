import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import {ReactComponent as recipeIcon} from "../../../assets/icons/recipe_icon.svg";

import {SelectInput} from "../../ui/select/SelectInput";
import {Page} from "../../ui/page/Page";
import {Image} from "../../ui/image/Image";

import {Table} from "../../features/table/Table";
import {DeleteModal} from "../../features/delete-modal/DeleteModal";

import * as actions from "./RecipesActions";
import dayjs from "dayjs";
import _ from "lodash";

export const Recipes = () => {
    const navigate = useNavigate();

    const [recipes, setRecipes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([])
    const [ingredients, setIngredients] = useState([])

    const [page, setPage] = useState(1)
    const [sort, setSort] = useState(null)

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [totalPages, setTotalPages] = useState(1)
    const [searchParams, setSearchParams] = useSearchParams()

    const preparation_time = [
        {value: {min: 0, max: 10}, label: "Under 10 min"},
        {value: {min: 10, max: 30}, label: "10-30 min"},
        {value: {min: 30, max: 60}, label: "30-60 min"},
        {value: {min: 60, max: 90}, label: "60-90 min"},
        {value: {min: 90, max: 0}, label: "Over 90 min"}
    ]

    const columns = [
        {
            Header: "Recipe",
            accessor: "name",
            Cell: ({row}) => (
                <div className="name">
                    <Image src={row.original.image?.url} alt={row.original.name}/>
                    {row.original.name}
                </div>
            ),
        },
        {
            Header: "Published",
            accessor: "created_at",
            Cell: ({row}) => (
                <span>{dayjs(row.original.created_at).format("DD/MM/YYYY")}</span>
            ),
        },
    ];

    const mapIdName = (data) => data.map(item => ({
        ..._.omit(item, "id", "name"), value: item.id, label: item.name
    }))

    const fetchRecipes = (filters, page, sort) => {
        actions.fetchRecipes((response) => {
            setRecipes(response.recipes);
            setTotalPages(response.pages)
        }, filters, page, sort?.by, sort?.order);
    }

    useEffect(() => {
        actions.fetchData((dataForFilters) => {
            setCategories(mapIdName(dataForFilters.categories))
            setIngredients(mapIdName(dataForFilters.ingredients))
            setTags((mapIdName(dataForFilters.tags)))
        })
    }, []);

    const getParams = () => ({
        category_id: searchParams.get("category_id"),
        ingredient_ids: searchParams.get("ingredient_ids")?.split(','),
        tag_ids: searchParams.get("tag_ids")?.split(','),
        min_prep_time: searchParams.get("min_prep_time"),
        max_prep_time: searchParams.get("max_prep_time")
    })

    useEffect(() => {
        setPage(1)
        fetchRecipes(getParams(), 1, sort)
    }, [searchParams]);

    useEffect(() => {
        fetchRecipes(getParams(), page, sort)
    }, [page, sort]);

    const handleEditClick = (id) => {
        navigate(`/recipe/${id}`);
    };

    const handleDeleteClick = (name, id) => {
        setShowDeleteModal({name, id});
    };

    const deleteRecipe = (id) => {
        actions.deleteRecipe(id, () => {
            setRecipes(recipes.filter((recipe) => recipe.id !== id));
            setShowDeleteModal(false);
        });
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    return (
        <Page
            title="Recipes"
            buttonName="+ Add new"
            onButtonClick={() => navigate("/recipe")}
            className="recipes"
            icon={recipeIcon}>
            <div className="filters">
                <SelectInput options={categories}
                             placeholder="Categories"
                             value={categories.find(category => category.value === parseInt(searchParams.get("category_id")))}
                             isClearable
                             onChange={(option) => {
                                 if(option) {
                                     searchParams.set("category_id", option.value)
                                 }else{
                                     searchParams.delete("category_id")
                                 }
                                 setSearchParams(searchParams)
                             }}/>
                <SelectInput options={ingredients}
                             value={ingredients.filter(ingredient => searchParams.get("ingredient_ids")?.split(',').map(ingredient_id => parseInt(ingredient_id)).includes(ingredient.value))}
                             onChange={(option) => {
                                 if(option.length){
                                     searchParams.set("ingredient_ids", option.map(item => item.value))
                                 }else{
                                     searchParams.delete("ingredient_ids")
                                 }
                                 setSearchParams(searchParams)
                             }}
                             placeholder="Ingredients"
                             isMulti/>
                <SelectInput options={tags}
                             value={tags.filter(tag => searchParams.get("tag_ids")?.split(',').map(tag_id => parseInt(tag_id)).includes(tag.value))}
                             onChange={(option) => {
                                 if(option.length){
                                     searchParams.set("tag_ids", option.map(item => item.value))
                                 }else{
                                     searchParams.delete("tag_ids")
                                 }
                                 setSearchParams(searchParams)
                             }}
                             placeholder="Tags"
                             isMulti/>
                <SelectInput options={preparation_time}
                             value={preparation_time.find(time => time.value.min === parseInt(searchParams.get("min_prep_time")) && time.value.max === parseInt(searchParams.get("max_prep_time")))}
                             onChange={(option) => {
                                 if (option) {
                                     searchParams.set("min_prep_time", option?.value.min)
                                     searchParams.set("max_prep_time", option?.value.max)
                                 } else {
                                     searchParams.delete("min_prep_time")
                                     searchParams.delete("max_prep_time")
                                 }
                                 setSearchParams(searchParams)
                             }}
                             placeholder="Preparation Time"
                             isClearable/>
            </div>
            <Table
                columns={columns}
                data={recipes}
                onEdit={handleEditClick}
                onDelete={handleDeleteClick}
                onSort={setSort}
                isSorted={sort}
                totalPages={totalPages}
                currentPage={page}
                onPageChange={setPage}
                canBeSorted
                isPaginated
            />
            {showDeleteModal && (
                <DeleteModal
                    title="Recipes"
                    element="Recipe"
                    toDeleteName={showDeleteModal.name}
                    onClose={closeDeleteModal}
                    onDelete={() => deleteRecipe(showDeleteModal.id)}
                />
            )}
        </Page>
    );
};
