import axios from "axios";

export const fetchTags = (callback, page) => {
  const config = {
    url: "tags",
    method: "GET",
    params: {page}
  };
  axios.request(config).then((response) => {
    callback(response.data);
  });
};



export const deleteTag = (id, callback) => {
  const config = {
    url: `tags/${id}`,
    method: "DELETE",
  };
  axios.request(config).then(() => callback());
};


