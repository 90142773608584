import React from 'react';

import "./UserDropdownOptions.scss"
import {useNavigate} from "react-router-dom";
export const UserDropdownOptions = () => {
    const navigate = useNavigate()
    const logout = () => {
        localStorage.clear('auth')
        navigate("/login")
    }
    return (
        <div className="user-dropdown-options">
            <span className="option" onClick={logout}>log out</span>
        </div>
    );
};