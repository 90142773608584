import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";

import "./SubPage.scss"

import {ReactComponent as navigationIcon} from "../../../assets/icons/navigation_icon.svg";

import {Page} from "../../ui/page/Page";
import {Form} from "../../ui/form/Form";
import {Label} from "../../ui/label/Label";
import {Input} from "../../ui/input/Input";
import {TextEditor} from "../../ui/textEditor/TextEditor";
import {Button} from "../../ui/button/Button";

import {EditorState, convertToRaw, ContentState} from "draft-js";
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from "html-to-draftjs";

import * as actions from "./SubPageActions"

export const SubPage = () => {
    const navigate = useNavigate()
    const params = useParams()

    const [prevName, setPrevName] = useState("")
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

    const {values, handleSubmit, handleChange, setValues, setFieldValue} = useFormik({
        initialValues: {
            name: "",
            content: ""
        },
        onSubmit: (values) => {
            actions.saveSubpage(values, () => navigate("/sub-pages"))
        }
    })

    useEffect(() => {
        if(params.id){
            actions.getSubpage(params.id, (subpage) => {
                setPrevName(subpage.name)
                setValues(subpage)
                const blocksFromHtml = htmlToDraft(subpage.content)
                const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks)
                setEditorState(EditorState.createWithContent(contentState))
            })
        }
    }, [params.id]);

    useEffect(() => {
        setFieldValue("content", convertContentToHtml())
    }, [editorState]);

    const convertContentToHtml = () => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }

    return (
        <Page className="subpage" icon={navigationIcon} title="Main navigation" subTitle={params.id ? `${prevName}` : `Add new subpage`}>
            <form onSubmit={handleSubmit}>
                <Form>
                    <Label required>Name</Label>
                    <Input placeholder="Name" id="name" value={values.name} onChange={handleChange}/>
                    <Label required>Content</Label>
                    <TextEditor value={editorState} onChange={setEditorState}/>
                </Form>
                <div className="footer">
                    <Button type="submit">Save</Button>
                </div>
            </form>
        </Page>
    );
};