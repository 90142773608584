import React, {useState} from "react";

import "./Navbar.scss";
import {Dropdown} from "../dropdown/Dropdown";

import {ReactComponent as menuIcon} from "../../../assets/icons/menu_icon.svg";
import {ReactComponent as recipesIcon} from "../../../assets/icons/recipe_icon.svg";
import {ReactComponent as reportIcon} from "../../../assets/icons/reports_icon.svg";
import {ReactComponent as usersIcon} from "../../../assets/icons/users_icon.svg";
import {ReactComponent as articlesIcon} from "../../../assets/icons/articles_icon.svg";
import {ReactComponent as subpagesIcon} from "../../../assets/icons/sub_page_icon.svg";

import {Icon} from "../../ui/icon/Icon";

import classNames from "classnames";
import {NavItem} from "./NavItem";
import {UserDisplay} from "../user/UserDisplay";
import {Searchbar} from "../searchbar/Searchbar";
import placeholder from "../../../assets/placeholder.jpg";

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState(JSON.parse(localStorage.getItem('auth')))

    const navbarItemsClasses = classNames("items", {
        open: isOpen,
    });


    return (
        <div className="navbar">
            <div className={navbarItemsClasses}>
                <div className="left-content">
                    {authenticatedUser.user.role === "user" && (
                        <>
                            <NavItem to="/recipes" icon={recipesIcon} subPage="recipe">
                                Recipes
                            </NavItem>
                            <NavItem to="/articles" icon={articlesIcon} subPage="article">
                                Articles
                            </NavItem>
                            <NavItem to="/sub-pages" icon={subpagesIcon} subPage="sub-page">
                                Sub-pages
                            </NavItem>
                            <NavItem to="/reports" icon={reportIcon}>
                                Reports
                            </NavItem>
                        </>
                    )}
                    {authenticatedUser.user.role === "admin" && (
                        <NavItem to="/users" icon={usersIcon} subPage="user">
                            Users
                        </NavItem>
                    )}
                    <Searchbar />
                </div>
                <div className="right-content">
                    {authenticatedUser.user.role === "user" && (
                        <Dropdown
                            items={[
                                {
                                    name: "Ingredients",
                                    path: "/ingredients",
                                },
                                {
                                    name: "Categories",
                                    path: "/categories",
                                },
                                {
                                    name: "Tags",
                                    path: "/tags",
                                },
                            ]}
                        />
                    )}
                    <UserDisplay image={authenticatedUser.user.image?.url} username={authenticatedUser.user.name}/>
                </div>
            </div>
            <div
                className="menu"
                onClick={() => setIsOpen((prevState) => !prevState)}>
                <Icon icon={menuIcon}/>
            </div>
        </div>
    );
};
