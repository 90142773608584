import React, {useEffect, useState} from 'react';
import "./SubPages.scss"

import {Page} from "../../ui/page/Page";
import {useNavigate} from "react-router-dom";
import {Icon} from "../../ui/icon/Icon";

import {ReactComponent as navigationIcon} from "../../../assets/icons/navigation_icon.svg";


import * as actions from "./SubPagesActions"
import {DeleteModal} from "../../features/delete-modal/DeleteModal";
import {Button} from "../../ui/button/Button";
import {DndContext} from "@dnd-kit/core";
import {arrayMove, SortableContext} from "@dnd-kit/sortable";
import SubpageItem from "./SubpageItem";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import _ from "lodash";

export const SubPages = () => {
    const navigate = useNavigate()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [subpages, setSubpages] = useState([])

    useEffect(() => {
        actions.fetchSubpages((subpages) => {
            setSubpages(subpages)
        })
    }, []);

    const deleteSubpage = (id) => {
        actions.deleteSubpage(id, () => {
            setSubpages(prevSubpages => prevSubpages.filter(subpage => subpage.id !== id))
            setShowDeleteModal(false)
        })
    }

    const handleDragEnd = (event) => {
        const {active, over} = event

        if (over && active.id !== over.id) {
            const oldIndex = subpages.findIndex(item => item.id === active.id)
            const newIndex = subpages.findIndex(item => item.id === over.id)
            const newSubpages =  arrayMove(subpages, oldIndex, newIndex)
            setSubpages(newSubpages)

            changeOrder(newSubpages[newIndex], newIndex)
        }
    }

    const changeOrder = (subpage, order) => {
        actions.updateSubpage({..._.omit(subpage, "order"), order: order})
    }

    return (<Page className="subpages" icon={navigationIcon} title="Main navigation" buttonName="+ Add new"
                  onButtonClick={() => navigate("/sub-page")}>
        <div className="content">
            <SubpageItem subpage={{name: "Recipes"}}/>
            <SubpageItem subpage={{name: "Articles"}}/>
            <DndContext

                modifiers={[restrictToVerticalAxis]}
                onDragEnd={handleDragEnd}
            >
                <SortableContext items={subpages}>
                    {subpages.map(subpage => (
                        <SubpageItem subpage={subpage} onDelete={setShowDeleteModal} key={subpage.id}/>
                    ))}
                </SortableContext>
            </DndContext>
        </div>
        {showDeleteModal && (<DeleteModal title="Sub-pages" element="sub-page" toDeleteName={showDeleteModal.name}
                                          onClose={() => setShowDeleteModal(false)}
                                          onDelete={() => deleteSubpage(showDeleteModal.id)}/>)}
    </Page>);
};