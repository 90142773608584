import axios from "axios"

export const saveArticle = (article, callback) => {
    const id = article.id
    const config = {
        url: id ? `articles/${id}` : "articles",
        method: id ? "PATCH" : "POST",
        data: {article}
    }
    axios.request(config).then(() => callback())
}

export const getArticle = (id, callback) => {
    const config = {
        url: `articles/${id}`,
        method: "GET",
    }

    axios.request(config).then((response) => callback(response.data))
}