import React, {useEffect, useState} from 'react';

import {ReactComponent as articlesIcon} from "../../../assets/icons/articles_icon.svg";

import {Page} from "../../ui/page/Page";
import {Table} from "../../features/table/Table";
import {useNavigate} from "react-router-dom";

import * as actions from "./ArticlesActions"
import dayjs from "dayjs";
import {DeleteModal} from "../../features/delete-modal/DeleteModal";

import placeholder from "../../../assets/placeholder.jpg";

const columns = [
    {
        Header: "Article",
        accessor: "name",
        Cell: ({row}) => (
            <div className="name">
                <img src={row.original.image? row.original.image.url : placeholder} alt={row.original.name} />
                {row.original.name}
            </div>
        )
    },
    {
        Header: "Published",
        accessor: "created_at",
        Cell: ({row}) => (
            <span>{dayjs(row.original.created_at).format("DD/MM/YYYY")}</span>
        ),
    },
]
export const Articles = () => {
    const navigate = useNavigate()

    const [sort, setSort] = useState(null)

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const [articles, setArticles] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    useEffect(() => {
        actions.fetchArticles((data) => {
            setTotalPages(data.pages)
            setArticles(data.articles)
        },page, sort?.by, sort?.order)
    }, [sort, page]);

    const handleEditClick = (id) => {
        navigate(`/article/${id}`)
    }

    const handleDeleteClick = (name, id) => {
        setShowDeleteModal({name, id})
    }

    const deleteArticle = (id) => {
        actions.deleteArticles(id, () => {
            setArticles(prev => prev.filter(item => item.id !== id))
            setShowDeleteModal(false)
        })
    }

    return (
        <Page icon={articlesIcon} title="Articles" buttonName="+ Add new" onButtonClick={() => navigate("/article")}>
            <Table columns={columns}
                   data={articles}
                   onDelete={handleDeleteClick}
                   onEdit={handleEditClick}
                   onSort={setSort}
                   isSorted={sort}
                   totalPages={totalPages}
                   currentPage={page}
                   onPageChange={setPage}
                   canBeSorted
                   isPaginated
            />
            {showDeleteModal && (
                <DeleteModal title="Articles" element="Article" toDeleteName={showDeleteModal.name}
                             onDelete={() => deleteArticle(showDeleteModal.id)}
                             onClose={() => setShowDeleteModal(false)}
                />
            )}
        </Page>
    );
};