import React, {useEffect, useState} from 'react';
import "./PublicRecipe.scss"

import {Page} from "../../../ui/page/Page";

import * as actions from "./PublicRecipeActions"
import {useParams} from "react-router-dom";
import {Image} from "../../../ui/image/Image";
import {Badge} from "../../../ui/badge/Badge";
import dayjs from "dayjs";

export const PublicRecipe = () => {
    const params = useParams()
    const [recipe, setRecipe] = useState([])

    useEffect(() => {
        actions.fetchRecipes(params.id, params.recipe_id, (recipe) => {
            setRecipe(recipe)
        })
    }, []);

    return (
        <Page className="public-recipe" title="You're reading">
            <div className="content">
                <div className="recipe">
                    <div className="header">
                        <span className="name">{recipe.name}</span>
                        <span className="created_at">{dayjs(recipe.created_at).format("MMMM DD, YYYY")}</span>
                    </div>
                    <div className="category">
                        <Image src={recipe.category?.image?.url}/>
                        {recipe.category?.name}
                    </div>
                    <Image src={recipe.image?.url}/>
                    <div className="tags">
                        {recipe.tags?.map(tag => (
                            <Badge>{tag}</Badge>
                        ))}
                    </div>
                    <span className="prep_time">Preparation time: {recipe.preparation_time} minutes</span>
                    <div className="ingredients">
                        <span className="title">Ingredients</span>
                        {recipe.recipe_ingredients?.map(ingredient => (
                            <div className="ingredient">
                                <span>{ingredient.name}</span>
                                {" - "}
                                <span>{ingredient.amount} </span>
                                <span>{ingredient.unit}</span>
                            </div>
                        ))}
                    </div>
                    <div className="description">
                        {recipe.description}
                    </div>
                </div>
            </div>
        </Page>
    );
};
