import React from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import { Icon } from "../../ui/icon/Icon";

import "./NavItem.scss";
import classNames from "classnames";

export const NavItem = ({ children, to, icon, subPage }) => {
  const {pathname} = useLocation()
  const navlinkClasses = classNames("nav-item", {
    active: pathname.slice(0, pathname.lastIndexOf('/')) === `/${subPage}` || pathname === `/${subPage}`
  })
  return (
    <NavLink to={to} className={navlinkClasses}>
      {icon && <Icon icon={icon} />}
      {children}
    </NavLink>
  );
};
