import React from 'react';

import "./Option.scss"

import {components} from "react-select";
import {Checkbox} from "../checkbox/Checkbox";

export const Option = (props) => {
    return (
        <components.Option {...props}>
            <div className="option">
                <span>{props.label}</span>
                <Checkbox checked={props.isSelected}/>
            </div>
        </components.Option>
    );
};