import React from "react";

import {useTable} from "react-table";

import "./Table.scss";
import {ReactComponent as editIcon} from "../../../assets/icons/edit_icon.svg";
import {ReactComponent as deleteIcon} from "../../../assets/icons/delete_icon.svg";
import {Button} from "../../ui/button/Button";
import classNames from "classnames";

import {ReactComponent as sortUpIcon} from "../../../assets/icons/sort_up.svg";
import {ReactComponent as sortDownIcon} from "../../../assets/icons/sort_down.svg";
import {Icon} from "../../ui/icon/Icon";
import {Pagination} from "../pagination/Pagination";

export const Table = ({
                          data,
                          columns,
                          onEdit,
                          onDelete,
                          variant,
                          footerContent,
                          canBeSorted,
                          onSort,
                          isSorted,
                          isPaginated,
                          totalPages,
                          currentPage,
                          onPageChange
                      }) => {
    const variantStyles = classNames({
        [variant]: variant
    })

    const optionsStyles = classNames("options", {
        [variant]: variant
    })
    const handleSortClick = (orderBy) => {
        if (isSorted?.by === orderBy) {
            if (isSorted.order === "ASC") {
                onSort(prev => ({...prev, order: "DESC"}))
            } else {
                onSort(null)
            }
        } else {
            onSort({by: orderBy, order: "ASC"})
        }
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns, data, onDelete, onEdit});

    return (
        <div className="table">
            <table className={variantStyles} {...getTableProps()}>
                <thead className={variantStyles}>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th className={variantStyles} {...column.getHeaderProps()}>
                      <span className="head" onClick={() => canBeSorted && handleSortClick(column.id)}>
                        {column.render("Header")}
                          {
                              canBeSorted && (
                                  <span className="sorting">
                                    <Icon icon={sortUpIcon}
                                          className={isSorted?.by === column.id && isSorted.order === "ASC" && "enabled"}/>
                                    <Icon icon={sortDownIcon}
                                          className={isSorted?.by === column.id && isSorted.order === "DESC" && "enabled"}/>
                                </span>
                              )
                          }
                      </span>
                            </th>
                        ))}
                        {variant !== "reports" && (
                            <th className={variantStyles}>Options</th>
                        )}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()} className={variant}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr className={variantStyles} key={i} {...row.getRowProps}>
                            {row.cells.map((cell, i) => {
                                return (
                                    <td
                                        key={i}
                                        {...cell.getCellProps()}
                                        className={variantStyles}>
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                            {variant !== "reports" && (
                                <td className={optionsStyles}>
                                    <Button
                                        variant="icon"
                                        icon={editIcon}
                                        onClick={() => onEdit(variant === "ingredients" ? row.original : row.original.id)}
                                    />
                                    <Button
                                        variant="icon"
                                        icon={deleteIcon}
                                        onClick={() => onDelete(variant === "ingredients" ? row.original : row.original.name, row.original.id)}
                                    />
                                </td>
                            )}
                        </tr>
                    );
                })}
                {footerContent &&
                    <tr className="footer">
                        <td colSpan={5}>
                            {footerContent}
                        </td>
                    </tr>
                }
                </tbody>
            </table>
            {isPaginated &&
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={onPageChange}/>}
        </div>
    );
};
