import axios from "axios";

export const saveRecipe = (recipe, callback) => {
    const id = recipe.id
    const config = {
        url: id ? `recipes/${id}` : "recipes",
        method: id ? "PATCH" : "POST",
        data: {recipe},
    };
    axios.request(config).then(() => callback());
};

export const getRecipe = (id, callback) => {
    const config = {
        url: `recipes/${id}`,
        method: "GET",
    };
    axios.request(config).then((response) => callback(response.data));
};

export const fetchData = (callback) => {
    const endpoints = ['categories', 'tags', 'ingredients']
    axios.all(endpoints.map(endpoint => axios.get(endpoint))).then(axios.spread(({data: {categories}}, {data: {tags}}, {data: {ingredients}}) => {
        callback({categories, tags, ingredients})
    }))
}