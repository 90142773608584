import React from 'react';

import "./Article.scss"

import {Image} from "../../../ui/image/Image";
import {ContentState, convertFromHTML} from "draft-js";
import {useNavigate} from "react-router-dom";

export const Article = ({article}) => {
    const navigate = useNavigate()

    const createArticleShorcut = (article) => {
        const blocksFromHtml = convertFromHTML(article)
        const contentState = ContentState.createFromBlockArray(
            blocksFromHtml.contentBlocks,
            blocksFromHtml.entityMap,
        )
        return contentState.getPlainText().slice(0, 100) + (contentState.getPlainText().length > 100 ? "..." : "")
    }

    return (
        <div className="article" key={article.id} onClick={() => navigate(`${article.id}`)}>
            <Image src={article.image?.url}/>
            <span className="title">{article.name}</span>
            <span className="description">
                {createArticleShorcut(article.content)}
            </span>
        </div>
    );
};