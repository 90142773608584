import React from 'react';
import "./SubpageItem.scss"

import {Icon} from "../../ui/icon/Icon";
import {Button} from "../../ui/button/Button";

import {ReactComponent as editIcon} from "../../../assets/icons/edit_icon.svg";
import {ReactComponent as deleteIcon} from "../../../assets/icons/delete_icon.svg";
import {ReactComponent as pageIcon} from "../../../assets/icons/page_icon.svg";

import {useNavigate} from "react-router-dom";
import {useSortable} from "@dnd-kit/sortable";

import {CSS} from '@dnd-kit/utilities'

const SubpageItem = ({subpage, onDelete}) => {
    const navigate = useNavigate()
    const {
        attributes,
        listeners,
        setActivatorNodeRef,
        setNodeRef, transform,
        transition
    } = useSortable({id: subpage.id})

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <div className="sub-page-item" ref={setNodeRef} {...attributes} style={style}>
            <span className={`name ${subpage.id ? "draggable" : ""}`} ref={setActivatorNodeRef} {...listeners}><Icon icon={pageIcon}/>{subpage.name}</span>
            {subpage.id && (
                <span className="options">
                    <Button icon={editIcon} variant="icon"
                            onClick={() => navigate(`/sub-page/${subpage.id}`)}/>
                    <Button icon={deleteIcon} variant="icon"
                            onClick={() => onDelete(subpage)}/>
                </span>
            )}
        </div>
    );
};

export default SubpageItem;