import React, {useEffect, useState} from "react";

import {ReactComponent as categoriesIcon} from "../../../assets/icons/categories_icon.svg";

import {useFormik} from "formik";
import {PhotoInput} from "../../ui/photoInput/PhotoInput.js";
import {useNavigate, useParams} from "react-router-dom";

import * as actions from "./CategoryActions";
import * as yup from "yup";

import {Page} from "../../ui/page/Page.js";
import {Form} from "../../ui/form/Form.js";
import {Input} from "../../ui/input/Input.js";
import {Label} from "../../ui/label/Label.js";
import {Error} from "../../ui/error/Error.js";

import _ from "lodash";

const categorySchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    image: yup
        .object()
        .nullable(true)
        .shape({
            file: yup
                .mixed()
                .nullable(true)
                .test("fileType", "The file must be an image", (value) => {
                    if (value) {
                        const supportedFormats = ["image/jpg", "image/jpeg", "image/png"];
                        return supportedFormats.includes(value.type);
                    }
                    return true;
                })
                .test("fileSize", "The file is too large", (value) => {
                    if (value) {
                        return value.size <= 31457280;
                    }
                    return true;
                }),
        }),
});

export const Category = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [prevName, setPrevName] = useState("");

    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: {
            name: "",
            image: null
        },
        validationSchema: categorySchema,
        onSubmit: (values) => {
            actions.saveCategory({
                ..._.omit(values, "image"),
                photo_id: values.image?.id || null,
            }, () => navigate("/categories"))
        },
    });

    useEffect(() => {
        if (params.id) {
            actions.getCategory(params.id, (category) => {
                setValues(category);
                setPrevName(category.name);
            });
        }
    }, []);

    return (
        <Page
            className="category"
            icon={categoriesIcon}
            title="Categories"
            subTitle={params.id ? `Edit - ${prevName}` : "New"}
            onButtonClick={handleSubmit}
            buttonName="Save">
            <form autoComplete="off" id="category" onSubmit={handleSubmit}>
                <Form>
                    <Label required>Name</Label>
                    <Input
                        error={errors.name && touched.name}
                        value={values.name}
                        onChange={handleChange}
                        id="name"
                        type="text"
                        placeholder="Category name"
                    />
                    {errors.name && touched.name ? <Error>{errors.name}</Error> : null}

                    <Label>Photo</Label>
                    <PhotoInput
                        value={values.image?.url}
                        onPhotoUpload={setFieldValue}
                    />
                    {errors.image && touched.image ? <Error>{errors.image}</Error> : null}
                </Form>
            </form>
        </Page>
    );
};
